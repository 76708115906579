<template>
  <div>
    <ImageHeader
      :text="
        isLoading
          ? 'Lade..'
          : teams.length == 0
          ? 'Keine Teams vorhanden'
          : 'Teamübersicht'
      "
      imageUrl="http://h-team.torbara.com/images/soccer/page-header-bg.jpg"
    />
    <div v-if="isLoading">
      <div class="container loadingContainer">
        <LoadingComponent />
      </div>
    </div>

    <div v-else>
      <div class="container">
        <Title title="Teamübersicht" />

        <transition name="fade">
          <InfoBox
            v-show="errorStatus !== null"
            :text="errorStatus"
            linkText=""
            link=""
            isClosable="no"
          />
        </transition>

        <div class="row m-2" v-if="errorStatus == null">
          <div class="col-md-2 mt-2">
            <span>Team suchen:</span>
          </div>
          <div class="col-md-10">
            <div class="wrap">
              <input
                type="text"
                @change="filterTeams()"
                v-model="teamName"
                value="Nach Team suchen"
              />
              <button><i class="fa fa-search" aria-hidden="true"></i></button>
            </div>
          </div>
        </div>

        <div
          class="col-md-12 col-sm-12 col-xs-12 standings-table"
          v-if="errorStatus == null"
        >
          <table class="standing-full">
            <tbody>
              <tr>
                <th>Verein</th>
                <!-- <th>Gewonnen</th>
                <th>Unentschieden</th>
                <th>Verloren</th>
                <th>Tore</th> -->
                <th>Spiele</th>
                <th>Punkte</th>
              </tr>
              <tr
                v-for="team in teams"
                :key="team.image"
                :class="team.shortName == 'HSV' ? 'hsvColor' : 'normalColor'"
              >
                <td class="up">
                  <div :style="genStandingsStyle(getTeamStanding(team.name))">
                    {{ getTeamStanding(team.name) }}.
                  </div>
                  <span class="team"
                    ><img
                      :src="team.image"
                      width="30"
                      height="30"
                      alt="team-logo"
                    /> </span
                  ><a :href="'#/team/' + team.id">{{ team.name }}</a>
                </td>
                <!-- <td>{{ team.wins }}</td>
                <td>{{ team.draw }}</td>
                <td>{{ team.lost }}</td>
                <td>{{ team.goals }} - {{ team.opponentGoals }}</td> -->
                <td>{{ team.matches }}</td>
                <td class="points">
                  <span>{{ team.points }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingComponent from "@/app/components/loading.vue";
import Title from "@/app/components/title.vue";
import ImageHeader from "@/app/components/image-header.vue";
import InfoBox from "@/app/components/info-box.vue";

export default {
  components: {
    LoadingComponent,
    ImageHeader,
    InfoBox,
    Title,
  },
  data() {
    return {
      status: "",
      teamName: "",
      teams: [],
      teamsFull: [],
    };
  },

  computed: {
    errorStatus() {
      return this.$store.getters.error;
    },
    isLoading() {
      return this.$store.getters.isLoading;
    },
  },
  methods: {
    /**
     * Macht die ersten 3 Teams fett
     *
     */
    genStandingsStyle(standing) {
      let style = "display: inline-block;";
      if (standing < 4) style += "font-weight: bold;";

      return style;
    },
    // A method that returns the standing of a team.
    getTeamStanding(name) {
      let standing = 0;

      for (let index = 0; index < this.teamsFull.length; index++) {
        standing++;
        const team = this.teamsFull[index];
        if (team.name == name) return standing;
      }

      return 0;
    },
    /**
     * Sortiert die Teams absteigend nach Punkten
     */
    sortTeams(teams) {
      if (!teams || teams.length == 0) return [];

      teams.sort((a, b) => b.goals - a.goals);

      return teams;
    },
    /**
     * Laesst die Teams nach der Var. "teamName" filtern
     * Durchsucht wird der Teamname und die Abkürzung
     */
    filterTeams() {
      if (!this.teamName || this.teamName === "") {
        this.teams = this.teamsFull;
        return;
      }
      const s = this.teamName.toLowerCase();
      this.teams = this.teamsFull.filter(
        (t) =>
          t.name.toLowerCase().match(s) || t.shortName.toLowerCase().match(s)
      );
    },
    /**
     * Laedt die Teams aus der API
     */
    async loadTeams() {
      await this.$store.dispatch("loadTeams");

      if (this.errorStatus) {
        return;
      }

      this.teamsFull = this.$store.getters.teams;
      this.teams = this.teamsFull;
    },
  },
  /**
   * Laesst bei Start die Teams laden
   */
  mounted() {
    this.loadTeams();
  },
};
</script>

<style scoped>
a {
  color: #000;
}
.standings-table .standing-full {
  white-space: nowrap;
}
.standing-full tr th {
  height: 68px;
  font-size: 15px;
  color: #fff;
  background: #141414;
  text-align: center;
}
table {
  width: 100%;
  overflow: hidden;
}

table tr {
  background: #fcfcfc;
  transition: all 0.8s;
}

table tr .normalColor {
  background: #fcfcfc;
}
.hsvColor {
  background: #6495ed29 !important;
}

table tr td {
  padding: 0 20px;
  height: 50px;
  line-height: normal;
  border-bottom: 1px solid #e6e6e6;
  color: #141414;
  text-align: center;
}
table tr th:first-child {
  padding-left: 20px;
}
table tr td:first-child {
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  text-align: left;
}
.standing-full tr td {
  padding: 0 20px;
  height: 80px;
  border-bottom: 1px solid #e9e9e9;
}

.standing-full td img {
  height: 30px;
  width: 30px;
  object-fit: contain;
  margin: 0 15px;
}
.standing-full tr td.points span {
  width: 40px;
  height: 30px;
  line-height: 30px;
  border-radius: 9px;
  background: #141414;
  display: inline-block;
  color: #fff;
}

.standing-full tr:hover {
  background: #fc0;
}
.wrap {
  border: 1px solid #e0e0e0;
  position: relative;
}
input[type="number"] {
  width: 100%;
  box-sizing: border-box;
  padding: 0 40px 0 20px;
  height: 48px;
  line-height: 56px;
  font-size: 14px;
  color: #8f8f8f;
  background: 0 0;
  border: none;
}
button {
  position: absolute;
  font-size: 13px;
  color: #999;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  background: 0 0;
  border: none;
}
</style>
